import React from 'react';
import { Link } from 'react-router-dom';

class Descriptions extends React.Component {
  render() {
  	return (
          <section className="section section-lg bg-web-desc">
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="text-white">Build your dream website today</h2>
                        <p className="padding-t-15 home-desc">Are the hassles of technology forcing you to procrastinate? Dont worry just tell us what u think and we'll make it happen.</p>
                        <a href="#pricing" className="btn btn-bg-white margin-t-30 waves-effect waves-light mb-5">View Plan & Pricing</a>
                    </div>
                </div>
            </div>
            <div className="bg-pattern-effect">
                <img src="images/bg-pattern.png" alt="" />
            </div>
        </section>
  	);
  }
}
export default Descriptions;