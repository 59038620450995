import React from 'react';


class Team extends React.Component {
  render() {
  	return (
     <section className="section pt-4" id="team">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">Behind The People</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center font-secondary padding-t-30">A bunch of folks set out to build stuff...and they work! </p>
                    </div>
                </div>
                <div className="row margin-t-50">
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-box text-center hover-effect">
                            <div className="team-wrapper">
                                <div className="team-member">
                                    <img alt="" src="images/team/azaz.jpg" className="img-fluid rounded" style={{height:'255px'}}/>
                                </div>
                            </div>
                            <h4 className="team-name">Azaz Khan</h4>
                            <p className="text-uppercase team-designation">CEO/Software Engineer</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="team-box text-center hover-effect">
                            <div className="team-wrapper">
                                <div className="team-member">
                                    <img alt="" src="images/team/hadi2.jpg" className="img-fluid rounded" />
                                </div>
                            </div>
                            <h4 className="team-name">Hadi Aamer Panwar</h4>
                            <p className="text-uppercase team-designation">CTO/Full Stack Developer</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-box text-center hover-effect">
                            <div className="team-wrapper">
                                <div className="team-member">
                                    <img alt="" src="images/team/fabian.jpg" className="img-fluid rounded" style={{height:'255px'}}/>
                                </div>
                            </div>
                            <h4 className="team-name">Fabian Enrique</h4>
                            <p className="text-uppercase team-designation">Full Stack Developer</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="team-box text-center hover-effect">
                            <div className="team-wrapper">
                                <div className="team-member">
                                    <img alt="" src="images/team/junaid.jpg" className="img-fluid rounded" style={{height:'255px'}}/>
                                </div>
                            </div>
                            <h4 className="team-name">Junaid Riaz</h4>
                            <p className="text-uppercase team-designation">3D Modeler/Designer</p>
                        </div>
                    </div>
                    {/*<div className="col-lg-3 col-sm-6">
                        <div className="team-box text-center hover-effect">
                            <div className="team-wrapper">
                                <div className="team-member">
                                    <img alt="" src="images/team/img-3.jpg" className="img-fluid rounded" />
                                </div>
                            </div>
                            <h4 className="team-name">Wanda Arthur</h4>
                            <p className="text-uppercase team-designation">Developer</p>
                        </div>
                    </div>*/}

               {/*     <div className="col-lg-3 col-sm-6">
                        <div className="team-box text-center hover-effect">
                            <div className="team-wrapper">
                                <div className="team-member">
                                    <img alt="" src="images/team/img-4.jpg" className="img-fluid rounded" />
                                </div>
                            </div>
                            <h4 className="team-name">Joshua Stemple</h4>
                            <p className="text-uppercase team-designation">Manager</p>
                        </div>
                    </div>*/}

                </div>
            </div>
        </section>
  	);
  }
}
export default Team;