import React from 'react';

class Services extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">Our Services</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">We craft digital, graphic and dimensional thinking, to create category leading brand experiences that have meaning and add a value for our clients.</p>
                    </div>
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-display2 text-custom"></i>
                            <h4 className="padding-t-15">Web Design and Development</h4>
                            <p className="padding-t-15 text-muted">We create world dominating designs with the modern tech stack to always be relevant in the long run.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-phone text-custom"></i>
                            <h4 className="padding-t-15">Mobile App Development</h4>
                            <p className="padding-t-15 text-muted">Keep your business mobile so customers can access it anywhere,anytime.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-shopbag text-custom"></i>
                            <h4 className="padding-t-15">E-Commerce Solutions</h4>
                            <p className="padding-t-15 text-muted">Spare your customers the pain of walking down to your store.</p>
                        </div>
                    </div>
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-users text-custom"></i>
                            <h4 className="padding-t-15">IT Resource Allocation.</h4>
                            <p className="padding-t-15 text-muted">Dont let your idea loose the pace. Plug and Play, Teams that cater your needs.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-cloud text-custom"></i>
                            <h4 className="padding-t-15">Managed Cloud Hosting</h4>
                            <p className="padding-t-15 text-muted">Your applications under supervision of Tech Ninjas always inaction to chop the bugs off.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-network text-custom"></i>
                            <h4 className="padding-t-15">Digital Marketing</h4>
                            <p className="padding-t-15 text-muted">We give you the best digital marketing services to increase your conversions, repeat the traffic,and increase your brand online visibility.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  	);
  }
}
export default Services;