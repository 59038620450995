import React from 'react';
import { Link } from 'react-router-dom';

class Pricing extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="pricing">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Our Pricing</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle font-secondary text-muted text-center padding-t-30">Considering the economic and existential crisis of this age, we made a plan that would be ECONOMICAL and FEASIBLE for you</p>
                </div>
            </div>
            <div className="row margin-t-50">
                <div className="col-lg-4">
                    <div className="text-center pricing-box hover-effect">
                        <h4 className="text-uppercase">Economy</h4>
                        <h1>3600 USD</h1>
                        <h6 className="text-uppercase text-muted">Billing Per Year</h6>
                        <div className="pricing-border"></div>
                        <div className="plan-features margin-t-30">
                            <h4>OR <b className="text-custom">300 USD/month</b></h4>
                            <p>Domain + Hosting <b className="text-custom">25GB</b></p>
                            <p>SEO</p>
                            <p>Website Development</p>
                            <p>Maintenance</p>
                        </div>
                        <Link to="JavaScript:Void(0);" className="btn btn-custom waves-effect waves-light margin-t-30">Join Now</Link>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="text-center pricing-box bg-white hover-effect price-active">
                        <h4 className="text-uppercase">Deluxe</h4>
                        <h1>7200 USD</h1>
                        <h6 className="text-uppercase text-muted">For 3 Years</h6>
                        <div className="pricing-border"></div>
                        <div className="plan-features margin-t-30">
                            <h4>OR <b className="text-custom">200 USD/month</b></h4>
                            <p>Domain + Hosting <b className="text-custom">25GB</b></p>
                            <p>SEO</p>
                            <p>Website Development</p>
                            <p>Maintenance</p>
                        </div>
                        <Link to="JavaScript:Void(0);" className="btn btn-custom waves-effect waves-light margin-t-30">Join Now</Link>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="text-center pricing-box hover-effect">
                        <h4 className="text-uppercase">Ultimate</h4>
                        <h1>9000 USD</h1>
                        <h6 className="text-uppercase text-muted">For 5 Years</h6>
                        <div className="pricing-border"></div>
                        <div className="plan-features margin-t-30">
                            <h3>OR <b className="text-custom">150 USD/month</b></h3>
                            <p>Domain + Hosting <b className="text-custom">25GB</b></p>
                            <p>SEO</p>
                            <p>Website Development</p>
                            <p>Maintenance</p>
                        </div>
                        <Link to="JavaScript:Void(0);" className="btn btn-custom waves-effect waves-light margin-t-30">Join Now</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Pricing;