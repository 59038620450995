import React from 'react';
import { Link } from 'react-router-dom';

class Features extends React.Component {
  render() {
  	return (
        <section className="section bg-light" id="features">
        <div className="container">
            <div className="row vertical-content">
                <div className="col-lg-5">
                    <div className="features-box">
                        <h3>A company dedicated to providing you a website/mobile app that will stand out amongst others.</h3>
                        <p className="text-muted web-desc">We take good care of ensuring a fluid and robust design is implemented to take your business to new digital heights.</p>
                        <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                            <li className="">We put a lot of effort in design.</li>
                            <li className="">The most important ingredient of successful website.</li>
                            <li className="">Easy scalability so dont worry about adding new features later on.</li>
                            <li className="">Contact us to start the amazing journey in the digital world.</li>
                            <li className="">We'll do all the heavy lifting so you can sit back and watch the magic unfold.</li>
                        </ul>
                        <Link to="JavaScript:Void(0);" className="btn btn-custom margin-t-30 waves-effect waves-light">Learn More <i className="mdi mdi-arrow-right"></i></Link>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="features-img features-right text-right">
                        <img src="images/online-world.svg" alt="macbook image" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Features;