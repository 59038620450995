import React from 'react';
import { Link } from 'react-router-dom';

class Process extends React.Component {
  render() {
  	return (
        <section className="section bg-light">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Work Process</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">This is how we work in a nutshell, the inner workings are a giant machine of many cogs and wheels but you dont have to worry about that.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 text-center process-left-icon-1">
                    <i className="pe-7s-angle-right"></i>
                </div>
                <div className="col-lg-6 text-center process-left-icon-2">
                    <i className="pe-7s-angle-right"></i>
                </div>
            </div>
            <div className="row margin-t-50">
                <div className="col-lg-4 plan-line">
                    <div className="text-center process-box">
                        <i className="pe-7s-pen text-custom"></i>
                        <h4 className="padding-t-15">Tell us what you need</h4>
                        <p className="text-muted">Long descriptions, short conversations, children's drawings, however you can express yourself we will understand.</p>
                    </div>
                </div>
                <div className="col-lg-4 plan-line">
                    <div className="text-center process-box">
                        <i className="pe-7s-id text-custom"></i>
                        <h4 className="padding-t-15">Get free quotes</h4>
                        <p className="text-muted">Free stuff is always nice so we throw it around alot as well.</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="text-center process-box">
                        <i className="pe-7s-target text-custom"></i>
                        <h4 className="padding-t-15">Deliver high quality product</h4>
                        <p className="text-muted">The final result will be guaranteed to be beyond what you initially imagined...in a good way that is. And support is always there to update and maintain.</p>
                    </div>
                </div>
                <div className="text-center mx-auto">
                <Link to="JavaScript:Void(0);" className="btn btn-custom waves-light waves-effect margin-t-50">Get Started <i className="mdi mdi-arrow-right"></i></Link>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Process;