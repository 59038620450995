import React from 'react';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Features from './components/Features';
import Descriptions from './components/Descriptions';
import Pricing from './components/Pricing';
import Team from './components/Team';
import Process from './components/Process';
import Testi from './components/Testi';
import Started from './components/Started';
import Blog from './components/Blog';
import Contact from './components/Contact';
import SocialMedia from './components/SocialMedia';
import Footer from './components/Footer';
import FooterLinks from './components/FooterLinks';
import Switcher from './components/Switcher';
import Particles from 'react-particles-js';
import { Link } from 'react-router-dom';
import Aux from './hoc/Aux_';

class Home extends React.Component {
  render() {
    var mystyle = { position: 'absolute' };
      var bkg1 = {
          backgroundImage: 'url(images/wave-shape/wave1.png)',
      };
      var bkg2 = {
          backgroundImage: 'url(images/wave-shape/wave2.png)',
      };
      var bkg3 = {
          backgroundImage: 'url(images/wave-shape/wave3.png)',
      };
      var mystyle = { position: 'absolute' };
  	return (
        <Aux>
                {/* Navbar Component*/}
                <Navbar />
                <section className="section bg-home height-100vh" id="home">
                <div className="bg-overlay"></div>
                    <div className="" >
                        <div className="" >
                            <div className="container slidero">

                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 text-white text-center">
                                        <h1 className="home-title">We Help Businesses  Launch Their Products.</h1>
                                        <p className="padding-t-15 home-desc">Dinosaurs went extinct, but your brand will never see the same fate.</p>
                                           {/* <p className="play-shadow margin-t-30 margin-l-r-auto"> <Link to="http://vimeo.com/99025203"  className="play-btn video-play-icon"><i className="mdi mdi-play text-center"></i></Link></p>  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Particles style={mystyle}
                        params={
                            {
                                "particles": {
                                    "number": {
                                        "value": 213,
                                        "density": {
                                            "enable": true,
                                            "value_area": 1024.0252067743202
                                        }
                                    },
                                    "color": {
                                        "value": "#ffffff"
                                    },
                                    "shape": {
                                        "type": "polygon",
                                        "stroke": {
                                            "width": 0,
                                            "color": "#000000"
                                        },
                                        "polygon": {
                                            "nb_sides": 5
                                        },
                                        "image": {
                                            "src": "img/github.svg",
                                            "width": 100,
                                            "height": 100
                                        }
                                    },
                                    "opacity": {
                                        "value": 1,
                                        "random": true,
                                        "anim": {
                                            "enable": true,
                                            "speed": 1,
                                            "opacity_min": 0,
                                            "sync": false
                                        }
                                    },
                                    "size": {
                                        "value": 4.156863397155046,
                                        "random": true,
                                        "anim": {
                                            "enable": false,
                                            "speed": 4,
                                            "size_min": 0.3,
                                            "sync": false
                                        }
                                    },
                                    "line_linked": {
                                        "enable": false,
                                        "distance": 150,
                                        "color": "#ffffff",
                                        "opacity": 0.4,
                                        "width": 1
                                    },
                                    "move": {
                                        "enable": true,
                                        "speed": 1,
                                        "direction": "none",
                                        "random": true,
                                        "straight": false,
                                        "out_mode": "out",
                                        "bounce": false,
                                        "attract": {
                                            "enable": false,
                                            "rotateX": 600,
                                            "rotateY": 600
                                        }
                                    }
                                },
                                "interactivity": {
                                    "detect_on": "canvas",
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "bubble"
                                        },
                                        "onclick": {
                                            "enable": false,
                                            "mode": "push"
                                        },
                                        "resize": true
                                    },
                                    "modes": {
                                        "grab": {
                                            "distance": 400,
                                            "line_linked": {
                                                "opacity": 1
                                            }
                                        },
                                        "bubble": {
                                            "distance": 250,
                                            "size": 4.212299915754,
                                            "duration": 2,
                                            "opacity": 0.008424599831508001,
                                            "speed": 3
                                        },
                                        "repulse": {
                                            "distance": 400,
                                            "duration": 0.4
                                        },
                                        "push": {
                                            "particles_nb": 4
                                        },
                                        "remove": {
                                            "particles_nb": 2
                                        }
                                    }
                                },
                                "retina_detect": true
                            }
                        }/>
                    <div className="wave-effect wave-anim">
                        <div className="waves-shape shape-one">
                            <div className="wave wave-one" style={bkg1}></div>
                        </div>
                        <div className="waves-shape shape-two">
                            <div className="wave wave-two" style={bkg2}></div>
                        </div>
                        <div className="waves-shape shape-three">
                            <div className="wave wave-three" style={bkg3}></div>
                        </div>
                    </div>

                </section>

                {/* Services Component*/}
                <Services />

                {/* Features Component*/}
                <Features />

                {/* Descriptions Component*/}
                <Descriptions />

                {/* Pricing Component*/}
                <Pricing />

                {/* Team Component*/}
               {/* <Team />*/}

                {/* Process Component*/}
                <Process />

                {/* Testi Component*/}
                <Testi />

                {/* Started Component*/}
                <Started />

                {/* Blog Component*/}
               {/* <Blog />*/}

                {/* Contact Component*/}
                <Contact />

                {/* SocialMedia Component*/}
                <SocialMedia />

                {/* Footer Component*/}
                <Footer />

                {/* FooterLinks Component*/}
                <FooterLinks />

                {/* Switcher Component*/}
        </Aux>
  	);
  }
}

export default Home;
