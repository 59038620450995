import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
class Contact extends React.Component {
    state = {
          name : '',
          subject: '',
          email: '',
          message:''
    };
    sendMail = ()=>{
         /*   axios.post('http://spreadcode.org/sc_mailer/sendEmail.php',,{
                name:this.state.name,
                subject:this.state.subject,
                email:this.state.email,
                message:this.state.message
            })*/
         axios({
             method:'post',
             url:'http://spreadcode.org/sc_mailer/sendEmail.php',
             params:{
                 name:this.state.name,
                 subject:this.state.subject,
                 email:this.state.email,
                 message:this.state.message
             }
         })
                .then((response)=>{
                    if(response.data.statusCode=="200") {
                        Swal.fire(
                            'Your Message has been received',
                            'We will get back to you soon',
                            'success'
                        )
                    }
                    else Swal.fire(
                        'Whoops!',
                        'Maybe Try something else?',
                        'error'
                    )
                }).catch(err=>console.log(err));
    };
  render() {
  	return (
        <section className="section" id="contact">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Get In Touch</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">Contact us and lets finish that project you have been thinking about it.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="mt-4 pt-4">
                        <p className="mt-4"><span className="h5">Office Address 1:</span><br /> <span className="text-muted d-block mt-2">Spread Code,SuleimanKhil Plaza, 1st floor, Abbottabad Pakistan.</span></p>
                        <p className="mt-4"><span className="h5">Working Hours:</span><br /> <span className="text-muted d-block mt-2">11:00AM To 7:00PM PST</span></p>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="custom-form mt-4 pt-4">
                        <div id="message"></div>
                        <form name="contact" method="POST" data-netlify="true">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input name="name" id="name" type="text" className="form-control" placeholder="Your name*" onChange={e=>this.setState({name:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input name="email" id="email" type="email" className="form-control" placeholder="Your email*" onChange={e=>this.setState({email:e.target.value})} />
                                    </div>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mt-2">
                                        <input type="text" className="form-control" id="subject" placeholder="Your Subject.." onChange={e=>this.setState({subject:e.target.value})}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mt-2">
                                        <textarea name="comments" id="comments" rows="4" className="form-control" placeholder="Your message..." onChange={e=>this.setState({message:e.target.value})}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-right">
                                    <input type="button" id="submit" name="send" className="submitBnt btn btn-custom" value="Send Message" onClick={this.sendMail}/>
                                    <div id="simple-msg"></div>
                                </div>
                            </div>
                        </form>
                    </div>  
                </div>
            </div>
        </div>
    </section> 
  	);
  }
}
export default Contact;