import React from 'react';

class Testi extends React.Component {
  render() {
  	return (
         <section className="section" id="testi">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">What they've said</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center font-secondary padding-t-30">With 100+ projects successfully completed here are a few clients who have regularly worked with us.</p>
                    </div>
                </div>
                <div className="row margin-t-50">
                    <div className="col-lg-4">
                        <div className="testimonial-box hover-effect margin-t-30">
                            <img src="images/testimonials/profile.jpg" alt="" className="img-fluid d-block img-thumbnail rounded-circle" />
                            <div className="testimonial-decs">
                                <p className="text-muted text-center mb-0">“Great job developing a custom wordpress plugin to track user activity. Clear communication and fast, high quality work. Highly recommended.” </p>
                            </div>
                            <h5 className="text-center text-uppercase padding-t-15">Lee Betts -<span className="text-muted text-capitalize">UK</span></h5>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="testimonial-box hover-effect margin-t-30">
                            <img src="images/testimonials/profile.jpg" alt="" className="img-fluid d-block img-thumbnail rounded-circle" />
                            <div className="testimonial-decs">
                                <p className="text-muted text-center mb-0">“This was an absolutely massive project. They kept going through all of the ups and downs that we had as any big project does and didn’t stop until it was all working. they stayed up until the very late hours to ensure it was all completed. Totally recommend this team. This difficult project was definitely handled smoothly. Legend! ” </p>
                            </div>
                            <h5 className="text-center text-uppercase padding-t-15">Alex Baker- <span className="text-muted text-capitalize">UK</span></h5>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="testimonial-box hover-effect margin-t-30">
                            <img src="images/testimonials/profile.jpg" alt="" className="img-fluid d-block img-thumbnail rounded-circle" />
                            <div className="testimonial-decs">
                                <p className="text-muted text-center mb-0">“The final product was excellent. I could have communicated better on my end regarding the level of design help I wanted. Since I wasn't 100% with the website as it neared completion, I was able to contact Spreadcode in a jiffy. They finished it up post-call, presenting the final website within a couple days. The progress was excellent. Very happy and will continue to work with them on future projects.” </p>
                            </div>
                            <h5 className="text-center text-uppercase padding-t-15">Courtney Bourde - <span className="text-muted text-capitalize"></span>USA</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  	);
  }
}
export default Testi;